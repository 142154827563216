import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {ReactComponent as BgImg} from "images/wave.svg";
import Book from "images/book.png";
import Header, {DesktopNavLinks, LogoLink, NavLink, NavToggle,} from "../headers/light.js";
import {SectionDescription} from "../misc/Typography";
import {Subheading as SubheadingBase} from "../misc/Headings";
import {ReactComponent as SvgConnectingDot1} from "../../images/connecting-dot1.svg";
import {ReactComponent as SvgConnectingDot2} from "../../images/connecting-dot2.svg";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Subheading = tw(SubheadingBase)`text-center text-gray-200`;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center pt-10 md:pt-0 lg:pt-0`;
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;


const Container = styled.div((props) => [
    props.showBook
        ? tw`relative -mx-8 -mt-8 bg-center bg-cover min-h-144`
        : tw`relative -mx-8 -mt-8 bg-center bg-cover`,

    `background-image: linear-gradient(to right bottom, #55b8a5, #00b3bc, #00aad8, #009df0, #008af8, #1f7efa, #3b71f9, #5562f6, #6b5ef7, #7e58f7, #9052f6, #a14af4)`,
])

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`flex flex-1 flex-col justify-center items-center`;
const Description = tw(SectionDescription)`mx-auto text-center text-white`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl font-black text-white leading-snug sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const IllustrationContainer = styled.div`${tw`flex justify-center lg:justify-end items-center relative`} 
.img{
${tw`min-w-0 w-full max-w-lg z-20 h-full`}
}
`;
const ConnectingDo1 = styled(SvgConnectingDot1)`
  ${tw`pointer-events-none absolute top-0 h-128 w-144 z-10`}
  left: -10rem;
`;
const ConnectingDo2 = styled(SvgConnectingDot2)`
  ${tw`pointer-events-none absolute right-0 top-0 h-128 w-144 z-10`}
  right: -10rem;
`;
const PrimaryButton = tw.a`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-white text-gray-100 hocus:scale-105 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default ({
                    heading = <>Stay Young, <span>Be Strong,</span><br /> Live Forever</>,
                    subheading,
                    description = "Integrating Science, Engineering, and Data for Transformative Health Solutions",
                    buttonText = "DISCOVER REGENERATIVE INNOVATIONS",
                    onClick = () => {
                    },
                    showBook = false
                }) => {
    return (
        <Container showBook={showBook}>
            <HeroContainer>
                <StyledHeader/>
                {showBook ?
                    <TwoColumn>
                        <LeftColumn>
                            <Content>
                                {subheading && <Subheading>{subheading}</Subheading>}
                                <Heading>{heading}</Heading>
                                <Description>{description}</Description>
                                {buttonText ? (
                                    <PrimaryButton target="_blank" href="https://www.amazon.co.uk/dp/B0D7DJWT58/">
                                        <span className="gradient-text">{buttonText}</span>
                                    </PrimaryButton>
                                ) : null}
                            </Content>
                        </LeftColumn>
                        <RightColumn>
                            <IllustrationContainer>
                                <img className="img" src={Book} alt="Book"/>
                            </IllustrationContainer>
                        </RightColumn>
                    </TwoColumn> :
                    <Content style={{ padding: "5rem 0px 3rem" }}>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        <Description>{description}</Description>
                        {buttonText ? (
                            <PrimaryButton onClick={onClick}>
                                <span className="gradient-text">{buttonText}</span>
                            </PrimaryButton>
                        ) : null}
                    </Content>
                }
            </HeroContainer>
            <BgImg/>
            <ConnectingDo1/>
            <ConnectingDo2/>
        </Container>
    );
};
