import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Icon from "images/icon.png";

import Pillar1 from "images/pillars/pillar-1.png";
import Pillar2 from "images/pillars/pillar-2.png";
import Pillar3 from "images/pillars/pillar-3.png";
import Pillar4 from "images/pillars/pillar-4.png";
import Pillar5 from "images/pillars/pillar-5.png";
import Pillar6 from "images/pillars/pillar-6.png";

import ServiceIcon1 from "images/service-icon-1.svg";
import ServiceIcon2 from "images/service-icon-2.svg";
import ServiceIcon3 from "images/service-icon-3.svg";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Footer from "../components/footers/FiveColumnWithInputForm";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {SectionHeading, Subheading as SubheadingBase,} from "../components/misc/Headings";
import {SectionDescription} from "../components/misc/Typography";
import {ReactComponent as SvgDecoratorBlob1} from "../images/svg-decorator-blob-1.svg";
import SubscribeForm from "../components/forms/SubscribeForm";

export const Subheading = tw(
    SubheadingBase,
)`uppercase tracking-widest font-bold`;
export const Heading = tw(SectionHeading)``;
export const Description = tw(
    SectionDescription,
)`text-center mx-auto max-w-full`;
export const HighlightedText = tw.span`text-primary-500`;
export const TwoColumnContainer = styled.div`
  padding: 3px;
  border-radius: 5px;
  margin-top: 20px;
  background: linear-gradient(
    90deg,
    #5ac39a 11.92%,
    #2c68f4 47.2%,
    #bd42f4 74.09%
  );
`;
export const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto p-8 items-center bg-gray-100`}
  border-radius: 2px;
`;
export const BgContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-gray-200`}
`;
export const CurveContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-white`}
  border-radius: 100% 100% 0px 0px;
`;
export const Column = tw.div`w-auto max-w-md mx-auto md:max-w-none md:mx-0`;
export const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute top-0 h-128 w-144 opacity-50 z-10`}
`;
export default () => {
    return (
        <AnimationRevealPage>
            <Hero
                heading={
                    <>
                        A Systematic Approach to
                        <br/>
                        Regeneration
                    </>
                }
                roundedHeaderButton={true}
                subheading="UNLOCK YOUR SUPERHUMAN POTENTIAL"
                description='Traditional medicine often tackles problems with a "one size fits all" approach. But what if there is a better way?'
                buttonText="Learn More"
            />
            <Container>
                <ContentWithPaddingXl>
                    <Subheading style={{textAlign: "center"}}>
                        <span className="gradient-text">CORE PRINCIPLES</span>
                    </Subheading>

                    <Heading>
                        Your body is a complex system, not a collection of isolated parts.
                    </Heading>
                    <Description>
                        A sore knee isn't just about a single joint; it's a symptom of how
                        your entire musculoskeletal system is functioning. Systematic
                        thinking goes beyond the "mechanic's approach" of just replacing
                        parts. It's about understanding the entire system, how all the
                        components work together, and how seemingly unrelated issues might
                        be interconnected.
                    </Description>
                </ContentWithPaddingXl>
            </Container>
            <BgContainer>
                <Features
                    subheading=""
                    heading="Why is Systematic Thinking Crucial for Regeneration?"
                    description={
                        <>
                            In Prof. Paul Lee's revolutionary book, "Regeneration by Design",
                            you'll discover the power of systematic thinking in regeneration.
                            This approach moves beyond the limitations of a "mechanic's
                            approach" that simply replaces parts. Here's why it's crucial:
                        </>
                    }
                    cards={[
                        {
                            imageSrc: ServiceIcon1,
                            title: "Zooming Out",
                            description:
                                "Prof. Paul Lee takes a step back. He doesn't just focus on the immediate problem area. Instead, he \"zooms out\" to see the whole system, understanding the bigger picture of your body's interconnected systems. This holistic view allows him to address not just the pain point, but the underlying factors contributing to it.",
                        },
                        {
                            imageSrc: ServiceIcon2,
                            title: "Establishing a Baseline",
                            description:
                                "Prof. Lee will establish a baseline of your healthy movement patterns, creating a personalised roadmap for regeneration. This baseline serves as a reference point, allowing him to track progress and tailor interventions for optimal results.",
                        },
                        {
                            imageSrc: ServiceIcon3,
                            title: "Thinking Like an Engineer",
                            description:
                                'Think methodical problem-solving. Engineers approach problems by analysing all available options. "Regeneration by Design" delves into how this practical, step-by-step approach can be applied to regenerative medicine, leading to long-lasting solutions.',
                        },
                    ]}
                    leftContent
                    linkText=""
                />
                <CurveContainer>
                    <DecoratorBlob1 style={{right: "-22rem"}}/>
                    <Container>
                        <ContentWithPaddingXl style={{paddingBottom: 0}}>
                            <Subheading>
                                <img
                                    src={Icon}
                                    alt="Regen man"
                                    style={{margin: "2rem auto"}}
                                />
                            </Subheading>

                            <Heading>Discover the Four Pillars of the Regeneration</Heading>
                        </ContentWithPaddingXl>
                    </Container>
                </CurveContainer>
            </BgContainer>
            <MainFeature
                subheading={
                    <Subheading>
                        <span className="gradient-text">Physics:</span>
                    </Subheading>
                }
                imageSrc={Pillar1}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={
                    <>
                        The <HighlightedText>Symphony </HighlightedText> of Forces
                    </>
                }
                description={`Physics isn’t just about bones and mechanics. Dr Lee acknowledges it’s just one piece of the puzzle. "Regeneration by Design" explores the full spectrum of physics, including electricity, light, sound, and electromagnetism. The book delves into how these diverse forces interact with your body and how they are harnessed to create a personalised regeneration plan.`}
                hideButton
            />
            <MainFeature
                subheading={
                    <Subheading>
                        <span className="gradient-text">Chemistry:</span>
                    </Subheading>
                }
                imageSrc={Pillar2}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={
                    <>
                        The <HighlightedText>Art </HighlightedText> of Balance
                    </>
                }
                description={`Chemistry is all about influencing the delicate balance between regeneration and degeneration. Prof. Paul Lee explores how organic and inorganic chemistry work together to create treatments like PRP and hyaluronan injections. These treatments alter the environment within an injured area, promoting healing and pain relief.`}
                subdescription="Innovative implants that respond to your body's chemistry are on the horizon, showcasing the exciting future of this field."
                hideButton
                textOnLeft={false}
            />
            <MainFeature
                subheading={
                    <Subheading>
                        <span className="gradient-text">Biology:</span>
                    </Subheading>
                }
                imageSrc={Pillar3}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={
                    <>
                        The <HighlightedText> Evolving </HighlightedText> Frontier
                    </>
                }
                description={`Biology is the most dynamic pillar of regenerative medicine, with new tools and techniques emerging rapidly. Prof. Lee emphasises that the benefits of those advancements extend far beyond just improving mobility and explains how they impact everything even at a cellular and molecular level. As our understanding of biology deepens, even more revolutionary treatments are on the way.`}
                hideButton
            />
            <MainFeature
                subheading={
                    <Subheading>
                        <span className="gradient-text">Time:</span>
                    </Subheading>
                }
                imageSrc={Pillar4}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={
                    <>
                        Your Most Precious <HighlightedText>Investment </HighlightedText>
                    </>
                }
                description={`The final pillar – time, is your most valuable asset when it comes to  managing your health. Prof. Paul Lee explores the concept of "physiological time" - how proper care can slow down the aging process and keep your body younger than its chronological age. Invest time in the right treatments to reap the benefits for years to come.`}
                hideButton
                textOnLeft={false}
            />
            <Container>
                <DecoratorBlob1 style={{left: "-25rem"}}/>
                <ContentWithPaddingXl style={{paddingBottom: 0}}>
                    <Subheading style={{textAlign: "center"}}>
                        <span className="gradient-text">The Regeneration Equation</span>
                    </Subheading>

                    <Heading>Putting it All Together</Heading>
                    <TwoColumnContainer>
                        <TwoColumn>
                            <Column>
                                <Subheading style={{width: "max-content", marginRight: 10}}>
                                    <span className="gradient-text">Superhuman Regeneration</span>{" "}
                                    =
                                </Subheading>
                            </Column>
                            <Column>
                                <Description style={{textAlign: "left", marginLeft: 10}}>
                                    Systematic Thinking + The Four Pillars (Physics + Chemistry +
                                    Biology + Time) + Tools (Digital Body Bank + MAI)
                                </Description>
                            </Column>
                        </TwoColumn>
                    </TwoColumnContainer>
                </ContentWithPaddingXl>
            </Container>

            <MainFeature
                subheading=""
                imageSrc={Pillar5}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={<>Introducing Your Digital Body Bank</>}
                description={`Imagine a personalised blueprint of your optimal health - a digital twin capturing your body's unique baseline. This innovative concept, known as Digital Body Banking, goes beyond traditional medical records. By leveraging X-rays, MRI scans, CT scans, and advanced motion analysis, it creates a comprehensive picture of your musculoskeletal health. This rich repository identifies areas of improvement and anticipates potential issues based on your unique physiological profile.`}
                hideButton
            />
            <MainFeature
                subheading=""
                imageSrc={Pillar6}
                imageBorder={true}
                imageDecoratorBlob={true}
                heading={<>Demystifying Movement: Motion Artificial Intelligence</>}
                description={`For the first time, MAI allows us to analyse human movement in a completely new way. Unlike traditional methods like MRIs and CT scans, which offer static, two-dimensional views, MAI utilises advanced computer processing and artificial intelligence to capture your movement in three dimensions.`}
                subdescription="It offers a holistic view, unveiling the intricate connections between your joints. This paves the way for personalised interventions and a future where AI can monitor your movement and flag potential issues, before they become major problems, all from your mobile phone."
                hideButton
                textOnLeft={false}
            />
            <Container>
                <ContentWithPaddingXl>
                    <Subheading style={{textAlign: "center"}}>
            <span className="gradient-text">
              {" "}
                Regeneration = Systematic Thinking + Four Pillars + Tools{" "}
            </span>
                    </Subheading>

                    <Heading>Ready to take control of your health?</Heading>
                    <Description>
                        Embrace your potential for superhuman health and vitality. Order
                        your copy of "Regeneration by Design" today!
                    </Description>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <SubscribeForm/>
                    </div>
                </ContentWithPaddingXl>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
