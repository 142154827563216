import React from "react";
import tw from "twin.macro";
import {ContentWithPaddingXl} from "../../misc/Layouts";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import "./style.css"
import {SectionHeading} from "../../misc/Headings";
// Define common styled elements
const TimelineContainer = tw.div`relative my-16`;
const HeadingContainer = tw.div`mb-20`;
const Heading = tw(SectionHeading)``;

export const Timeline = ({ heading, cards = [] }) => {
    return (
        <TimelineContainer>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    <Heading>{heading}</Heading>
                </HeadingContainer>
                <VerticalTimeline lineColor="#2C68F4">
                    {cards.map(card =>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#ADC8F911'}}
                        date={card.subtitle}
                        dateClassName="vertical-timeline-element-date"
                    >
                        <h3 className="font-semibold">{card.title}</h3>
                        <p className="font-regular">
                            {card.description}
                        </p>
                    </VerticalTimelineElement>
                    )}

                </VerticalTimeline>
            </ContentWithPaddingXl>
        </TimelineContainer>
    );
};
