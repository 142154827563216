import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Home from './pages/LandingPage.js';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Services from './pages/ServicesPage';
import SuperHuman from './pages/Superhuman';
import Approach from './pages/Approach';
import GlobalStyles from "./styles/GlobalStyles";
import Blogs from "./pages/Blogs";
import BlogViewPage from "./pages/BlogViewPage";


const routes = [
    {
        path: "/about-us", component: AboutUs,
        title: "Regeneration Man - About Us",
        description: "We are experts in Transforming Musculoskeletal Health, offering cutting-edge therapies and personalised care to improve mobility, alleviate pain, and enhance quality of life."
    },
    {
        path: "/contact-us",
        component: ContactUs,
        title: "Regeneration Man - Contact Us",
        description: "If you require assistance or have any technical questions regarding Regeneration Man,our customer support team is ready to assist you. Please email us at live@regenman.com"
    },
    {path: "/blogs/:slug", component: BlogViewPage},
    {
        path: "/blogs",
        component: Blogs,
        title: "Regeneration Man - Read our latest blogs on health and regeneration.",
        description: "Read our latest blogs on health and regeneration."
    },
    {
        path: "/services",
        component: Services,
        title: "Regeneration Man - A Systematic Approach to Regeneration",
        description: "Traditional medicine often tackles problems with a \"one size fits all\" approach. But what if there is a better way?"
    },
    {
        path: "/super-human",
        component: SuperHuman,
        title: "Regeneration Man - Unleash Your Inner Superhuman: Design a Life of Optimal Health and Vitality",
        description: "\"Regeneration by Design\" by Paul Lee, offers a revolutionary approach to aging, transforming it from a slow decline into a journey of superhuman strength and rejuvenation."
    },
    {
        path: "/approach",
        component: Approach,
        title: "Regeneration Man - Unlock Your Superhuman Potential: A Systematic Approach to Regeneration",
        description: "Your body is a complex system, not a collection of isolated parts. A sore knee isn't just about a single joint; it's a symptom of how your entire musculoskeletal system is functioning."
    },
    {
        path: "/",
        component: Home,
        title: "Regeneration Man - Stay Young, Be Strong, Live Forever",
        description: "Stay young, be strong, and live forever with Regeneration Man's methods and tips."
    },
];

const App = () => (
    <>
        <GlobalStyles/>
        <Router>
            <Routes>
                {routes.map(({path, component: Component, title, description}, index) => (
                    <Route key={index} exact path={path} element={
                        <>
                            <Helmet>
                                <title>{title}</title>
                                <link rel="canonical" href={`https://regenman.com${path}`}/>
                                <meta name="description" content={description}/>
                                <meta property="og:title" content={title}/>
                                <meta property="og:description" content={description}/>
                                <meta name="twitter:title" content={title}/>
                                <meta name="twitter:description" content={description}/>
                            </Helmet>
                            <Component/>
                        </>
                    }/>
                ))}
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </Router>
    </>
);

export default App;
