import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {useParams} from "react-router-dom";
import {Container} from "components/misc/Layouts";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "components/misc/Headings";

import blogs from "../posts.json"
import styled from "styled-components";
import {Helmet} from "react-helmet";
import Hero from "../components/hero/BackgroundAsImageWithCenteredContent";

const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Description = styled.div`
${tw`mt-6 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
       font-family: "Poppins", Serif;
       color: #131E47;
           font-weight: normal;
  p{
    font-size: 1rem;
    margin-bottom: 12px;
    }
    li{
    font-size: 1rem;
    margin-bottom: 12px;
    }
    h2{
        font-size: 1.25rem;
        font-family: "Poppins Semibold", Serif;
        margin-bottom: 12px;
        margin-top: 20px;
        color: #2C68F4;
    }
    ol, ul{
       padding-top: 10px;
           margin-bottom: 12px;
    }
    strong{
        font-family: Poppins Medium;
        color: #131E47;
        font-weight: normal;
        font-size: 1.125rem;
     }
`;
const CreationDate = tw.div`mt-4 uppercase text-right text-gray-600 italic font-semibold text-base`;
const ImageOverlay = tw.div`w-full absolute right-0 bottom-0 h-full bg-primary-500 opacity-25`;

export default () => {

    const {slug} = useParams();

    const post = blogs.find(a => a.slug === slug);

    if (!post) {
        return <HeadingRow>
            <Heading>404 Blog not found"</Heading>
        </HeadingRow>
    }
    return (
        <>
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.shortDescription}/>
                <meta property="og:title" content={post.title}/>
                <meta property="og:description" content={post.title}/>
                <meta name="twitter:title" content={post.title}/>
                <meta name="twitter:description" content={post.title}/>
            </Helmet>
            <AnimationRevealPage>
                <Hero
                    subheading=""
                    buttonText=""
                    heading={post.title}
                    description={post.shortDescription}
                />
                <Container>
                    <ContentWithPaddingLg>
                        <CreationDate>
                            Date Created: {new Date(post.date).toLocaleDateString("en-GB")}
                        </CreationDate>
                        <div style={{position: "relative"}}>
                            <ImageOverlay/>
                            <img src={"https://regenman.com/" + post.thumbnail}
                                 style={{margin: "30px auto", mixBlendMode: "color-burn"}} alt={post.title}/>
                        </div>
                        <Description>
                            <div dangerouslySetInnerHTML={{__html: post.content}}/>
                        </Description>
                    </ContentWithPaddingLg>
                </Container>
                <Footer/>
            </AnimationRevealPage>
        </>

    );
};