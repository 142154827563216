import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase,} from "components/misc/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios from "axios";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
        ? tw`md:mr-12 lg:mr-16 md:order-first`
        : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const SuccessMessage = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-green-500`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = styled.input`
  ${tw`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
  border-color: #7D849D;
`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
                    subheading = "Contact Us",
                    heading = (
                        <>
                            <span tw="text-primary-500">Inquire by</span>
                            <wbr/>
                            filling up the form
                        </>
                    ),
                    description = "We appreciate your interest in MAI-Motion. For any inquiries, support, related questions, please feel free to reach out to us using the contact information provided below. Our team is dedicated to providing prompt and helpful assistance.",
                    submitButtonText = "Send",
                    textOnLeft = true,
                }) => {
    const [success, setSuccess] = useState("");
    const [formValues, setFormValues] = useState({
        email: "",
        name: "",
        subject: "",
        description: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // API call to submit the form data

        axios.post(`${process.env.REACT_APP_HTTP_DOMAIN}/contact-us`, formValues, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                setSuccess(response.data.message);
                setTimeout(() => {
                    const elem = document.getElementById("success_id");
                    if (elem) {
                        elem.scrollIntoView({behavior: "smooth", block: "center"});
                    }
                }, 100);
                setFormValues({
                    email: "",
                    name: "",
                    subject: "",
                    description: "",
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    };

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc}/>
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form onSubmit={handleSubmit}>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Your Email Address"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                            <Input
                                type="text"
                                name="name"
                                placeholder="Full Name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                            <Input
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={formValues.subject}
                                onChange={handleChange}
                            />
                            <Textarea
                                name="description"
                                placeholder="Your Message Here"
                                value={formValues.description}
                                onChange={handleChange}
                            />
                            <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                        </Form>
                        {success ? (
                            <SuccessMessage id="success_id">{success}</SuccessMessage>
                        ) : null}
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
