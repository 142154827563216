import React from "react";

import Pillar1 from "images/superhuman/rectangle-1.png";
import Pillar2 from "images/superhuman/rectangle-2.png";
import Paul from "images/superhuman/paul.png";

import DNAIcon from "images/superhuman/dna.svg";
import LaptopIcon from "images/superhuman/laptop.svg";
import MuscleIcon from "images/superhuman/muscle.svg";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Footer from "../components/footers/FiveColumnWithInputForm";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  BgContainer,
  CurveContainer,
  DecoratorBlob1,
  Description,
  Heading,
  HighlightedText,
  Subheading,
} from "./ServicesPage.js";
import SubscribeForm from "../components/forms/SubscribeForm";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Unleash Your Inner Superhuman:
            <br />
            Design a Life of Optimal Health and Vitality
          </>
        }
        roundedHeaderButton={true}
        subheading="UNLOCK YOUR SUPERHUMAN POTENTIAL"
        description="Live Younger, Longer"
        buttonText=""
      />

      <MainFeature
        subheading={
          <Subheading>
            <span className="gradient-text">Problem</span>
          </Subheading>
        }
        imageSrc={Pillar1}
        imageBorder={true}
        imageDecoratorBlob={true}
        heading={
          <>
            Do you dream of staying <HighlightedText>active </HighlightedText>{" "}
            and <HighlightedText>vibrant </HighlightedText> well into your later
            years?
          </>
        }
        description={`Feeling limited by age-related decline? Maybe you used to be the picture of health, but lately, your body just doesn't bounce back the way it used to. Perhaps you see the warning signs – that nagging back pain, or the struggle to keep up with your loved ones. It's a wake-up call, but it's not too late.`}
        subdescription={`Traditional approaches to health often fall short. They focus on treating problems after they arise, neglecting the body’s remarkable potential for self-renewal.`}
        hideButton
      />
      <MainFeature
        subheading={
          <Subheading>
            <span className="gradient-text">Agitate</span>
          </Subheading>
        }
        imageSrc={Pillar2}
        imageBorder={true}
        imageDecoratorBlob={true}
        heading={
          <>
            What if you could unlock a hidden
            <HighlightedText> wellspring </HighlightedText> of vitality within
            yourself?
          </>
        }
        description={`Imagine a future where aches and pains become a distant memory, where you can conquer challenges, big and small, with a body and mind that feels strong and resilient. This isn't just a dream; it's a reality within your grasp.`}
        hideButton
        textOnLeft={false}
      />

      <BgContainer>
        <Features
          subheading={<span className="gradient-text">Solution</span>}
          heading="Design Your Superhuman Future"
          description={
            <>
              "Regeneration by Design" by Paul Lee, offers a revolutionary
              approach to aging, transforming it from a slow decline into a
              journey of superhuman strength and rejuvenation.
              <br />
              <br />
              Living longer isn't enough. You deserve to live well. Prof. Paul Lee
              bridges the gap between cutting-edge medicine and practical
              strategies. His book equips you with the science-backed tools to:
            </>
          }
          cards={[
            {
              imageSrc: LaptopIcon,
              title: "AI-Powered Blueprint for Regeneration",
              description:
                'Unlike books limited to traditional treatments, "Regeneration by Design" incorporates the latest discoveries in AI and machine learning to redefine human movement (kinematics) and propose practical applications for enhancing your body\'s natural regenerative capabilities.',
            },
            {
              imageSrc: MuscleIcon,
              title: "Elite Athlete Recovery Techniques",
              description:
                'Professor Lee with his extensive experience unveils the cutting-edge treatments and recovery techniques used by world-renowned athletes, making them accessible and applicable to anyone. These are the "sports medicine secrets" you can use to improve your own musculoskeletal health.',
            },
            {
              imageSrc: DNAIcon,
              title: "A Six-Step Program Powered by Science",
              description:
                "Forget vague theories! Master a data-driven approach that minimises external damage and maximises your body's ability to heal and regenerate, drawing on the latest research in physics, chemistry, and biology. This book provides a clear path to actively design your aging process for a longer, healthier life.",
            },
          ]}
          leftContent
          linkText=""
        />
        <CurveContainer style={{ borderRadius: "30% 30% 0px 0px" }}>
          <DecoratorBlob1 style={{ right: "-22rem" }} />
          <MainFeature
            subheading=""
            imageSrc={Paul}
            imageBorder={true}
            imageDecoratorBlob={true}
            heading={
              <>
                <HighlightedText> Prof. Paul Lee: </HighlightedText> Your Guide to
                Superhuman Health
              </>
            }
            description={
              <>
                Prof. Paul Lee is a true innovator in the field of health and
                longevity. His background encompasses dual expertise. A
                celebrated engineer and medical doctor, Prof. Lee possesses a
                comprehensive understanding of the human body, from its
                biomechanics to its cellular processes. This unique perspective
                allows him to translate complex medical science into actionable
                strategies for optimal health.
                <br />
                <br />
                Paul Lee isn't just a researcher; he's also a biohacker
                constantly exploring the latest advancements in science and
                technology to optimise human performance and longevity.
                <br />
                <br />
                Driven by a desire to empower others, Prof. Paul Lee is a
                passionate educator. He dedicates his life to sharing his
                science-backed insights with individuals who want to live a
                longer, healthier, and happier life.
                <br />
                <br />
                Prof. Lee's clinical, scientific, and certified background
                (engineer and medical doctor) guarantees his advice is grounded
                in rigorous research and practical application. With
                "Regeneration by Design," he offers you the tools to take
                control of your health and unlock a life of vitality well into
                your later years.
              </>
            }
            hideButton
            textOnLeft={false}
          />
        </CurveContainer>
      </BgContainer>
      <Container>
        <BgContainer>
          <ContentWithPaddingXl>
            <Subheading style={{ textAlign: "center" }}>
              <span className="gradient-text">
                {" "}
                Regeneration = Systematic Thinking + Four Pillars + Tools{" "}
              </span>
            </Subheading>
            <Heading>Ready to unlock your body's hidden potential?</Heading>
            <Description>
              Don't settle for a life limited by aches and pains. Embrace your
              potential for superhuman health and vitality. Become pain-free and
              energetic, confidently conquering your goals. Prof. Lee's innovative
              approach is now captured in his groundbreaking book, "Regeneration
              by Design."
              <br />
              <br />
              Don't wait! Pre-order your copy today and be among the first to
              unlock the secrets to superhuman health!
              <br />
              <br />
              Are you ready to reclaim your vitality and design a life of
              superhuman health? Simply enter your email address below to get
              notified when the book launches.
            </Description>
            <div style={{ display: "flex", justifyContent: "center" }}>
             <SubscribeForm />
            </div>
          </ContentWithPaddingXl>
        </BgContainer>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
