import React, {useState} from "react";
import tw from "twin.macro";
import Physics from "images/core-priciples/Phys-Icon-7.png";
import Chemistry from "images/core-priciples/Chem-Icon-1.png";
import Biology from "images/core-priciples/Bio-Icon-5.png";

import Logo1 from "images/media/logo-1.png";
import Logo2 from "images/media/logo-2.png";
import Logo3 from "images/media/logo-3.png";
import Logo4 from "images/media/logo-4.png";
import Logo5 from "images/media/logo-5.png";
import Logo6 from "images/media/logo-6.png";
import Logo7 from "images/media/logo-7.png";
import Logo8 from "images/media/logo-8.png";
import Logo9 from "images/media/logo-9.png";

// import Service1 from "images/services/service-1.png";
// import Service2 from "images/services/service-2.png";
// import Service3 from "images/services/service-3.png";
// import Service4 from "images/services/service-4.png";
// import Service5 from "images/services/service-5.png";
// import Service6 from "images/services/service-6.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Hero2 from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColSimple.js";
import MediaFeatures from "components/features/FourColSimpleImages.js";
// import GridColFeatures from "components/features/ThreeColGrid.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "../components/footers/FiveColumnWithInputForm";
import NewsLetterModal from "../components/NewsLetterModal";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

export default () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <AnimationRevealPage>
      <Hero showBook roundedHeaderButton={true} />
      <Hero2 roundedHeaderButton={true} />
      <MediaFeatures
        linkText=""
        images={[
          {
            imageSrc: Logo1,
            url: "https://www.telegraph.co.uk/sport/2023/03/24/true-difference-men-womens-bodies-what-means-trans-athlete/",
          },
          {
            imageSrc: Logo2,
            url: "https://www.express.co.uk/life-style/health/1468132/tennis-injuries-how-to-prevent-treat-them-evg",
          },
          {
            imageSrc: Logo3,
            url: "https://www.dailymail.co.uk/health/article-4846826/How-Ant-s-Robocop-leg-brace-helps-align-joint.html",
          },
          {
            imageSrc: Logo4,
            url: "https://www.golfchic.co.uk/the-regeneration-man-prof-paul-lee/",
          },
          {
            imageSrc: Logo5,
            url: "https://www.thesun.co.uk/health/23910382/balancing-exercises-help-live-longer/amp/",
          },
          {
            imageSrc: Logo6,
            url: "https://www.nike.com/a/exercises-for-arthritis",
          },
          {
            imageSrc: Logo7,
            url: "https://www.coachweb.com/gear/running-shoes/what-is-heel-to-toe-drop-in-running-shoes",
          },
          {
            imageSrc: Logo8,
            url: "https://www.theguardian.com/us",
          },
          {
            imageSrc: Logo9,
            url: "https://www.stylist.co.uk/fitness-health/running-tips-expert-fitness-advice-lululemon/510875",
          },
        ]}
      />
      <Features
        subheading={
          <Subheading className="gradient-text">CORE PRINCIPLES</Subheading>
        }
        heading="Core Principles of Our Approach"
        description="Grounded in science, our approach leverages the synergies of multiple disciplines to foster unparalleled health restoration."
        cards={[
          {
            imageSrc: Physics,
            title: "Physics",
            description:
              "Optimising physical mechanics for enhanced body function.",
          },
          {
            imageSrc: Chemistry,
            title: "Chemistry",
            description:
              "Balancing biochemical processes to support healing and growth.",
          },
          {
            imageSrc: Biology,
            title: "Biology",
            description: "Harnessing cellular potentials to revitalise health.",
          },
        ]}
        linkText=""
      />
{/*
      <GridColFeatures
        subheading={
          <Subheading className="gradient-text">FEATURED SERVICES</Subheading>
        }
        heading="Tailored Regenerative Treatments"
        description="From personalised diagnostics to ongoing care, discover how our bespoke services are designed to suit your unique health needs."
        grids={[
          {
            imageSrc: Service1,
            title: "Personalised Diagnostic Assessments",
            authorName: "Paul Lee",
            url: "#",
          },
          {
            imageSrc: Service2,
            title: "Customised Treatment Protocols",
            authorName: "Paul Lee",
            url: "#",
          },
          {
            imageSrc: Service3,
            title: "Long-Term Health Management",
            authorName: "Paul Lee",
            url: "#",
          },
        ]}
        linkText="OUR SERVICES"
      />
*/}

{/*      <GridColFeatures
        subheading={<Subheading className="gradient-text">Insights</Subheading>}
        heading="Latest Research and Insights"
        description="Stay at the forefront of regenerative medicine with our latest research findings and expert analyses."
        grids={[
          {
            imageSrc: Service4,
            title: "Interdum et malesuada fames ac ante ipsum primis",
            authorName: "April 20, 2024",
            url: "#",
          },
          {
            imageSrc: Service5,
            title: "Interdum et malesuada fames ac ante ipsum primis",
            authorName: "April 20, 2024",
            url: "#",
          },
          {
            imageSrc: Service6,
            title: "Interdum et malesuada fames ac ante ipsum primis",
            authorName: "April 20, 2024",
            url: "#",
          },
        ]}
        linkText="READ OUR LATEST INSIGHTS"
      />*/}

      <GetStarted />
      <Footer />
      {openModal && <NewsLetterModal onClose={() => setOpenModal(false)} />}
    </AnimationRevealPage>
  );
};
