import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import Rectangle1 from "images/team/Rectangle_1.png";
import Rectangle2 from "images/team/Rectangle_2.png";
import Rectangle3 from "images/team/Rectangle_3.png";
import Layer_11 from "images/team/Layer_1-1.png";
import Layer_12 from "images/team/Layer_1-2.png";
import Layer_13 from "images/team/Layer_1-3.png";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Features from "../components/features/ThreeColSimple";
// import GridColFeatures from "../components/features/ThreeColGrid";
// import Service4 from "../images/services/service-4.png";
// import Service5 from "../images/services/service-5.png";
// import Service6 from "../images/services/service-6.png";
import {BgContainer, CurveContainer, DecoratorBlob1} from "./ServicesPage";
import MediaFeatures from "../components/features/FourColSimpleImages";
import Image1 from "../images/media/logo-1.png";
import Image2 from "../images/media/logo-2.png";
import Image3 from "../images/media/logo-3.png";
import Image4 from "../images/media/logo-4.png";
import Image5 from "../images/media/logo-5.png";
import Image6 from "../images/media/logo-6.png";
import Image7 from "../images/media/logo-7.png";
import Image8 from "../images/media/logo-8.png";
import Image9 from "../images/media/logo-9.png";
import MainFeature from "../components/features/TwoColWithButton";
import PaulLee from "../images/paul_lee_homepage.png";
import Book from "../images/book.png";
// import ThreeColSlider from "../components/cards/ThreeColSlider";
import {Timeline} from "../components/features/timeline/Timeline";


// const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Description = styled.div`
${css`
ul{
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}
    li{
        font-size: 16px;
        margin-bottom: 10px;
        font-family: Poppins, Serif;
    }
`}
`;
const Divider = styled.div`
  ${tw`my-8`}
  ${css`border-top: 3px solid #dadada;`}
`;
export default () => {
    return (
        <AnimationRevealPage>
            <Hero
                roundedHeaderButton={true}
                heading="About Regen Man"
                description="Integrating Science, Engineering, and Data for Transformative Health Solutions"
                buttonText=""
            />
            <Features
                subheading={<span tw="text-black font-medium">Our mission is encapsulated in our motto</span>}
                heading={<span className="gradient-text">"Stay Young, Be Strong, Live Forever."</span>}
                description="Explore how our cutting-edge projects and research are setting new standards in health optimisation and regeneration."
                cards={[
                    {
                        imageSrc: Layer_11,
                        title: "Mission",
                        description:
                            "Regeneration through science, engineering, and medicine, helping you Stay Young, Be Strong, and Live Forever.",
                    },
                    {
                        imageSrc: Layer_12,
                        title: "Vision",
                        description:
                            "Be the world leader in regenerative medicine, using personalised, data-driven, and AI-powered approaches to health and wellness care.",
                    },
                    {
                        imageSrc: Layer_13,
                        title: "Strategy",
                        description:
                            "Advance regenerative medicine through innovative research, personalised health solutions, educational outreach, cutting-edge clinical practices, and comprehensive support, integrating engineering principles and artificial intelligence."
                    },
                ]}
                leftContent
                linkText=""
            />
            <BgContainer>
                <DecoratorBlob1 style={{left: "-22rem"}}/>
                <MainFeature
                    subheading={<span className="gradient-text" tw="text-lg">Founder</span>}
                    imageSrc={PaulLee}
                    imageBorder={false}
                    imageDecoratorBlob={true}
                    heading="Professor Paul Lee"
                    description={
                        <Description>
                            <p className="font-semibold" tw="mb-2 text-left">MBBch, MRCS, MFSEM (UK), MSc (Sports Med),
                                PhD,
                                FEBOT, FRCS (Tr & Orth)</p>
                            <ul>
                                <li>
                                    <span className="font-semibold">MBBch: </span>
                                    Bachelor of Medicine and Bachelor of Surgery, Cardiff University (2005)
                                </li>
                                <li>
                                    <span className="font-semibold">MRCS: </span>
                                    Member of the Royal College of Surgeons of Edinburgh (2008)
                                </li>
                                <li>
                                    <span className="font-semibold">MFSEM (UK): </span>
                                    Member of the Faculty of Sport and Exercise Medicine UK (2009)
                                </li>
                                <li>
                                    <span className="font-semibold">FEBOT: </span>
                                    Fellow of the European Board of Orthopaedic Trauma (2014)
                                </li>
                                <li>
                                    <span className="font-semibold">MSc: </span>
                                    Master of Science in Sports and Exercise Medicine, UWIC (2011)
                                </li>
                                <li>
                                    <span className="font-semibold">PhD: </span>
                                    Doctor of Philosophy in Medical Engineering, Cardiff University (2014)
                                </li>
                                <li>
                                    <span className="font-semibold">FRCS (T&O): </span>
                                    Fellow of the Royal College of Surgeons of Edinburgh in Trauma and Orthopaedic
                                    Surgery (2015)
                                </li>
                                <li>
                                    <span className="font-semibold">CCT: </span>
                                    Certificate of Completion of Training in Trauma and Orthopaedic Surgery (2016)
                                    Specialist Registration
                                </li>
                            </ul>

                        </Description>
                    }
                    hideButton
                    textOnLeft={false}
                />
                <CurveContainer style={{borderRadius: 0, marginLeft: 0, marginRight: 0}}>
                    <DecoratorBlob1 style={{right: "-22rem"}}/>
                    <Timeline
                        heading="Professional Experience"
                        cards={[
                            {
                                subtitle: "Since 2024",
                                title: "Founder of Regeneration Man",
                                description: 'Leading innovative solutions in health and longevity. Author for ‘Regeneration by Design’ a global best seller.',
                            },
                            {
                                subtitle: "Since 2021",
                                title: "Founder of MAI Motion:",
                                description: "Pioneering Motion Artificial Intelligence to optimize physical movements and enhance performance.",
                            },
                            {
                                subtitle: "Since 2019",
                                title: "Regional Surgical Ambassador, Royal College of Surgeon of Edinburgh",
                                description: "Representing the Royal College to promote surgical excellence and education.",
                            },
                            {
                                subtitle: "Since 2018",
                                title: "Medical Director, MSK Doctors:",
                                description: "Leading The Keep Clinic, MSK House Clinic, and Open MRI Clinic. Overseeing advanced regenerative medicine treatments and CQC registered clinic space.",
                            },
                            {
                                subtitle: "Since 2018",
                                title: "Harley Street Consultant:",
                                description: "Practicing at prestigious clinics in Harley Street, London. He practice at London  Sports Injuries Clinic, London Cartilage Clinic.",
                            },
                            {
                                subtitle: "Since 2017",
                                title: "Consultant Orthopaedic Surgeon, NHS England:",
                                description: "Providing expert orthopaedic care and surgery in the public healthcare system.",
                            },
                            {
                                subtitle: "Since 2016",
                                title: "Clinical Entrepreneur Fellow, Health Foundation, Q:",
                                description: "Engaging in management, clinical entrepreneurship, and quality improvement.",
                            },
                            {
                                subtitle: "2015-2016",
                                title: "Clinical Leadership Fellow, BOA:",
                                description: "Developing leadership skills and contributing to clinical management and regenerative medicine.",
                            },
                        ]}

                    />
                </CurveContainer>
            </BgContainer>

            <Divider/>
            <MainFeature1
                subheading={""}
                heading="Leadership and Innovation"
                description="Professor Paul Lee is renowned for his pioneering work in regenerative medicine, blending over two decades of elite sports and orthopaedic expertise with groundbreaking engineering insights. He is the visionary behind the Regeneration Principle, Motion Artificial Intelligence, Bio-markers, genetics and epigenetics, which are key components of his practice."
                buttonRounded={false}
                hideButton
                primaryButtonText="See Portfolio"
                imageSrc={Rectangle1}
            />
            <MainFeature1
                subheading={""}
                heading="Philosophy"
                buttonRounded={false}
                primaryButtonText="OUR SERVICES"
                hideButton
                description='His vision, encapsulated by the motto "Stay Young, Be Strong, Live Forever," drives his commitment to revolutionising health and longevity through science and engineering precision and innovation.'
                imageSrc={Rectangle2}
                textOnLeft={false}
            />
            <MainFeature1
                subheading={""}
                heading="Contributions to Regenerative Medicine"
                buttonRounded={false}
                hideButton
                description="Professor Lee has been involved in regenerative medicine since medical school, which leads to his PhD, focusing on the effects of mitochondrial membrane and performance enhancement in humans. His extensive experience and expertise in sports medicine have led him to excel in the field, becoming a visiting professor of sports medicine and visiting professor of medical engineering, successfully treating many Premiership footballers, elite athletes, and high-profile celebrities."
                imageSrc={Rectangle3}
                textOnLeft
            />
            <BgContainer>
                <DecoratorBlob1 style={{left: "-22rem"}}/>
                <MainFeature
                    subheading={<span className="gradient-text">The Book</span>}
                    imageSrc={Book}
                    imageBorder={false}
                    imageDecoratorBlob={true}
                    heading="Regeneration by Design - The Book"
                    primaryButtonUrl="https://www.amazon.co.uk/dp/B0D7DJWT58/"
                    description={
                        <Description>

                            <p style={{fontSize: 16, textAlign: "left"}} className="font-regular">
                                <span className="font-semibold">Book Overview: </span>
                                "Regeneration by Design" is Professor Lee’s comprehensive guide on enhancing and
                                rejuvenating the body's natural healing capacities. The book introduces the M3D3
                                approach: Minimizing Disturbances, Modifying Chemistry, Maximizing Biology, Determining
                                Physics, Digital Body Banking, and making informed Decisions.
                            </p>
                            <p style={{fontSize: 16, textAlign: "left"}} className="font-semibold" tw="my-4">Key Takeaways:</p>

                            <ul>
                                <li>
                                    <span className="font-semibold">Personalised Health Strategies: </span>
                                    Tailored to individual genetic and environmental factors.
                                </li>
                                <li>
                                    <span className="font-semibold">Innovative Technologies: </span>
                                    Insights into genetic testing, MAI, and personalised medicine, Focus on well-being,
                                    healthcare instead of ‘sick-care’.
                                </li>
                                <li>
                                    <span className="font-semibold">Practical Applications: </span>
                                    Step-by-step guides to implementing regenerative principles in daily life.
                                </li>
                            </ul>

                        </Description>
                    }
                    primaryButtonText="BUY NOW"
                    textOnLeft={false}
                />
            </BgContainer>
{/*            <ThreeColSlider/>
            <TeamCardGrid/>*/}
            {/*<BgContainer style={{marginTop: 80}}>*/}
               {/* <GridColFeatures
                    subheading={<Subheading className="gradient-text">Insights</Subheading>}
                    heading="Latest Research and Insights"
                    description="Stay at the forefront of regenerative medicine with our latest research findings and expert analyses."
                    cardBg="white"
                    grids={[
                        {
                            imageSrc: Service4,
                            title: "Interdum et malesuada fames ac ante ipsum primis",
                            authorName: "April 20, 2024",
                            url: "#",
                        },
                        {
                            imageSrc: Service5,
                            title: "Interdum et malesuada fames ac ante ipsum primis",
                            authorName: "April 20, 2024",
                            url: "#",
                        },
                        {
                            imageSrc: Service6,
                            title: "Interdum et malesuada fames ac ante ipsum primis",
                            authorName: "April 20, 2024",
                            url: "#",
                        },
                    ]}
                    linkText="READ OUR LATEST INSIGHTS"
                />
                <Divider/>*/}
                <MediaFeatures
                    linkText=""
                    images={[
                        {
                            imageSrc: Image1,
                            url: "https://www.telegraph.co.uk/sport/2023/03/24/true-difference-men-womens-bodies-what-means-trans-athlete/",
                        },
                        {
                            imageSrc: Image2,
                            url: "https://www.express.co.uk/life-style/health/1468132/tennis-injuries-how-to-prevent-treat-them-evg",
                        },
                        {
                            imageSrc: Image3,
                            url: "https://www.dailymail.co.uk/health/article-4846826/How-Ant-s-Robocop-leg-brace-helps-align-joint.html",
                        },
                        {
                            imageSrc: Image4,
                            url: "https://www.golfchic.co.uk/the-regeneration-man-prof-paul-lee/",
                        },
                        {
                            imageSrc: Image5,
                            url: "https://www.thesun.co.uk/health/23910382/balancing-exercises-help-live-longer/amp/",
                        },
                        {
                            imageSrc: Image6,
                            url: "https://www.nike.com/a/exercises-for-arthritis",
                        },
                        {
                            imageSrc: Image7,
                            url: "https://www.coachweb.com/gear/running-shoes/what-is-heel-to-toe-drop-in-running-shoes",
                        },
                        {
                            imageSrc: Image8,
                            url: "https://www.theguardian.com/us",
                        },
                        {
                            imageSrc: Image9,
                            url: "https://www.stylist.co.uk/fitness-health/running-tips-expert-fitness-advice-lululemon/510875",
                        },
                    ]}
                />
            {/*</BgContainer>*/}
            <Footer/>
        </AnimationRevealPage>
    );
};
