import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4 sm:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;
const PrimaryButton = styled(PrimaryButtonBase)(() => [
  tw`mt-8 md:mt-8 text-sm rounded-full`,
]);
const DecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-144 opacity-50 transform translate-x-96 translate-y-40`}
`;

export default ({ images = [], linkText = "Learn More", subheading = "" }) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading className="gradient-text">PROFESSOR LEE IN THE MEDIA</Heading>
        <Description>
          Discover how our groundbreaking work in regenerative medicine is
          shaping the future of healthcare across the globe.
        </Description>
        <ThreeColumnContainer>
          {images.map((image, i) => (
            <Column key={i}>
              <Card href={image.url} target="_blank">
                <img
                  src={image.imageSrc}
                  alt=""
                  style={{
                    width: "100%",
                    aspectRatio: "4/3",
                    objectFit: "contain",
                    height: 120,
                    padding: 25,
                  }}
                />
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
        {linkText ? (
          <div style={{ textAlign: "center" }}>
            <PrimaryButton>{linkText}</PrimaryButton>
          </div>
        ) : null}
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
