import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import {ReactComponent as SvgDecoratorBlob1} from "images/svg-decorator-blob-1.svg";
import PaulLee from "images/paul_lee_homepage.png";
import MaiMotion from "images/mai-motion.png";
import MskDoctors from "images/msk-doctors.png";
import LondonCartilage from "images/london-cartilage.svg";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Button = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

const IllustrationContainer = styled.div`
  ${tw`flex justify-center lg:justify-end items-center relative`}
  .hero-img {
    ${tw`min-w-0 w-full max-w-lg xl:max-w-3xl absolute opacity-10 left-1/2`}
  }
  .bg-img {
    ${tw`min-w-0 w-full max-w-lg xl:max-w-3xl z-20`}
  }
`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute left-0 bottom-0 h-128 w-144 opacity-50 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-sm tracking-wider font-semibold`}
  }
  .images {
  ${tw`flex items-center flex-wrap justify-center md:-ml-4 md:justify-start mt-2`}
  }
  img {
     ${tw`w-1/2 md:w-1/3 p-2 md:p-4 opacity-50`}
    filter: brightness(20%) contrast(200%)
  }
`;
const HighlightedText = tw.span`text-primary-500`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Subheading className="gradient-text">ABOUT US</Subheading>
            <Heading>
              The <HighlightedText>Regeneration</HighlightedText> Man
            </Heading>
            <Paragraph>
              Explore how Professor Paul Lee’s pioneering approach reshapes
              regeneration by expertly combining scientific rigor, engineering
              precision, and data-driven insights.
            </Paragraph>
            <Button
              onClick={() => window.open("https://www.amazon.co.uk/dp/B0D7DJWT58/")}
            >
              Learn More
            </Button>
            <CustomersLogoStrip>
              <p className="gradient-text">Supported By</p>
              <div className="images">
                <img src={MaiMotion} alt="MAI Motion" />
                <img src={LondonCartilage} alt="London Cartilage" />
                <img src={MskDoctors} alt="MSK Doctors" />
              </div>
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img className="bg-img" src={PaulLee} alt="Prof. Lee" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
