import React from "react";

import Pillar1 from "images/approach/rectangle-1.png";
import Pillar2 from "images/approach/rectangle-2.png";
import Pillar3 from "images/approach/rectangle-3.png";
import Pillar4 from "images/approach/rectangle-4.png";
import Pillar5 from "images/approach/rectangle-5.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Footer from "../components/footers/FiveColumnWithInputForm";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {
  BgContainer,
  CurveContainer,
  DecoratorBlob1,
  Description,
  Heading,
  HighlightedText,
  Subheading,
} from "./ServicesPage.js";
import Icon from "../images/icon.png";
import GridColFeatures from "../components/features/ThreeColGrid";
import TwoColGrid from "../components/cards/TwoColGrid";
import SubscribeForm from "../components/forms/SubscribeForm";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Unlock Your Superhuman Potential:
            <br />A Systematic Approach to Regeneration
          </>
        }
        roundedHeaderButton={true}
        subheading=""
        description={
          <>
            Traditional medicine often tackles problems with a "one size fits
            all" approach. But what if there's a better way?
            <br />
            <br />
            Your body is a complex system, not a collection of isolated parts. A
            sore knee isn't just about a single joint; it's a symptom of how
            your entire musculoskeletal system is functioning. Systematic
            thinking goes beyond the "mechanic's approach" of just replacing
            parts.
          </>
        }
        buttonText=""
      />

      <GridColFeatures
        subheading=""
        heading="Why is Systematic Thinking Crucial for Regeneration?"
        description={
          <>
            In Prof. Paul Lee's revolutionary book, "Regeneration by Design",
            you'll discover the power of systematic thinking in regeneration.
            This approach moves beyond the limitations of a "mechanic's
            approach" that simply replaces parts.
            <br />
            <br />
            Here's why it's crucial:
          </>
        }
        grids={[
          {
            imageSrc: Pillar5,
            title: "Zooming Out",
            authorName:
              "Prof. Paul Lee takes a step back. He doesn't just focus on the immediate problem area. Instead, he \"zooms out\" to see the whole system, understanding the bigger picture of your body's interconnected systems. This holistic view allows him to address not just the pain point, but the underlying factors contributing to it.",
          },
          {
            imageSrc: Pillar4,
            title: "Establishing a Baseline",
            authorName:
              "Prof. Lee will establish a baseline of your healthy movement patterns, creating a personalised roadmap for regeneration. This baseline serves as a reference point, allowing him to track progress and tailor interventions for optimal results.",
          },
          {
            imageSrc: Pillar3,
            title: "Thinking Like an Engineer",
            authorName:
              'Think methodical problem-solving. Engineers approach problems by analysing all available options. "Regeneration by Design" delves into how this practical, step-by-step approach can be applied to regenerative medicine, leading to long-lasting solutions.',
          },
        ]}
        linkText=""
      />

      <BgContainer>
        <TwoColGrid
          heading="Discover the Four Pillars of the Regeneration"
          cards={[
            {
              subtitle: <Subheading>Physics</Subheading>,
              title: "The Symphony of Forces",
              description:
                'Physics isn’t just about bones and mechanics. Dr Lee acknowledges it’s just one piece of the puzzle. "Regeneration by Design" explores the full spectrum of physics, including electricity, light, sound, and electromagnetism. The book delves into how these diverse forces interact with your body and how they are harnessed to create a personalised regeneration plan.',
            },
            {
              subtitle: <Subheading>Chemistry</Subheading>,
              title: "The Art of Balance",
              description: `Chemistry is all about influencing the delicate balance between regeneration and degeneration. Prof. Paul Lee explores how organic and inorganic chemistry work together to create treatments like PRP and hyaluronan injections. These treatments alter the environment within an injured area, promoting healing and pain relief. Innovative implants that respond to your body's chemistry are on the horizon, showcasing the exciting future of this field.`,
            },
            {
              subtitle: <Subheading>Biology</Subheading>,
              title: "The Evolving Frontier",
              description: `Biology is the most dynamic pillar of regenerative medicine, with new tools and techniques emerging rapidly. Prof. Lee emphasises that the benefits of those advancements extend far beyond just improving mobility and explains how they impact everything even at a cellular and molecular level. As our understanding of biology deepens, even more revolutionary treatments are on the way.`,
            },
            {
              subtitle: <Subheading>Time</Subheading>,
              title: "Your Most Precious Investment",
              description: `The final pillar – time, is your most valuable asset when it comes to managing your health. Prof. Paul Lee explores the concept of "physiological time" - how proper care can slow down the aging process and keep your body younger than its chronological age. Invest time in the right treatments to reap the benefits for years to come.`,
            },
          ]}
        />
        <CurveContainer style={{ borderRadius: "30% 30% 0px 0px" }}>
          <DecoratorBlob1 style={{ right: "-22rem" }} />
          <Container>
            <ContentWithPaddingXl style={{ paddingBottom: 0 }}>
              <Subheading>
                <img
                  src={Icon}
                  alt="Regen man"
                  style={{ margin: "2rem auto" }}
                />
              </Subheading>

              <Heading>
                The Regeneration Equation: Putting it All Together
              </Heading>
              <Description>
                Superhuman Regeneration = Systematic Thinking + The Four Pillars
                (Physics + Chemistry + Biology + Time) + Tools (Digital Body
                Bank + MAI)
              </Description>
            </ContentWithPaddingXl>
          </Container>
          <MainFeature
            subheading={""}
            imageSrc={Pillar1}
            imageBorder={true}
            imageDecoratorBlob={true}
            heading={
              <>
                Introducing Your
                <HighlightedText> Digital Body Bank </HighlightedText>
              </>
            }
            description={
              <>
                Imagine a personalised blueprint of your optimal health - a
                digital twin capturing your body's unique baseline. This
                innovative concept, known as Digital Body Banking, goes beyond
                traditional medical records. By leveraging X-rays, MRI scans, CT
                scans, and advanced motion analysis, it creates a comprehensive
                picture of your musculoskeletal health. This rich repository
                identifies areas of improvement and anticipates potential issues
                based on your unique physiological profile.
              </>
            }
            hideButton
            textOnLeft={false}
          />

          <MainFeature
            subheading={""}
            imageSrc={Pillar2}
            imageBorder={true}
            imageDecoratorBlob={true}
            heading={
              <>
                Demystifying Movement:
                <HighlightedText>
                  {" "}
                  Motion Artificial Intelligence{" "}
                </HighlightedText>
              </>
            }
            description={
              <>
                For the first time, MAI allows us to analyse human movement in a
                completely new way. Unlike traditional methods like MRIs and CT
                scans, which offer static, two-dimensional views, MAI utilises
                advanced computer processing and artificial intelligence to
                capture your movement in three dimensions. It offers a holistic
                view, unveiling the intricate connections between your joints.
                This paves the way for personalised interventions and a future
                where AI can monitor your movement and flag potential issues,
                before they become major problems, all from your mobile phone.
              </>
            }
            hideButton
          />
        </CurveContainer>
      </BgContainer>
      <Container>
        <BgContainer>
          <ContentWithPaddingXl>
            <Subheading style={{ textAlign: "center" }}>
              <span className="gradient-text">
                Regeneration = Systematic Thinking + Four Pillars + Tools{" "}
              </span>
            </Subheading>
            <Heading>Ready to unlock your body's hidden potential?</Heading>
            <Description>
              Don't settle for a life limited by aches and pains. Embrace your
              potential for superhuman health and vitality. Become pain-free and
              energetic, confidently conquering your goals. Prof. Lee's innovative
              approach is now captured in his groundbreaking book, "Regeneration
              by Design."
              <br />
              <br />
              Don't wait! Pre-order your copy today and be among the first to
              unlock the secrets to superhuman health!
              <br />
              <br />
              Are you ready to reclaim your vitality and design a life of
              superhuman health? Simply enter your email address below to get
              notified when the book launches.
            </Description>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SubscribeForm />
            </div>
          </ContentWithPaddingXl>{" "}
        </BgContainer>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
