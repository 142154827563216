import React, {useMemo, useState} from "react";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {PrimaryButton} from "components/misc/Buttons";

import bloglist from "../posts.json"
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../components/hero/BackgroundAsImageWithCenteredContent";

const Posts = tw.div`sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg border-2 rounded-lg h-full`;
const ImageOverlay = tw.div`w-full absolute right-0 bottom-0 h-full bg-primary-500 opacity-25`;
const Image = styled.div`
  ${props => css`background-image: url("${props.image}");`}
  ${tw`h-64 w-full bg-cover bg-center relative rounded-t-md`}
`;
const Info = tw.div`p-8`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-semibold mb-3 text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
                    headingText = "Blogs",
                }) => {
    const [visible, setVisible] = useState(7);
    const onLoadMoreClick = () => {
        setVisible(v => v + 6);
    };

    const blogs = useMemo(() => bloglist.sort((a, b) => new Date(b.date) - new Date(a.date)), []);

    return (
        <AnimationRevealPage>
            <Hero
                subheading=""
                buttonText=""
                heading="Blogs"
                description=""
            />
            <Container>
                <ContentWithPaddingXl style={{ paddingTop: 0 }}>
                    <Posts>
                        {blogs.slice(0, visible).map((post, index) => (
                            <PostContainer key={index} featured={post.featured}>
                                <Post className="group" as="a" href={`/blogs/${post.slug}`}>
                                    <Image image={"https://regenman.com/"+post.thumbnail}>
                                        <ImageOverlay />
                                    </Image>
                                    <Info>
                                        {post.category && <Category>{post.category}</Category>}
                                        <CreationDate>{new Date(post.date).toLocaleDateString("en-GB")}</CreationDate>
                                        <Title>{post.title}</Title>
                                        {post.shortDescription && <Description>{post.shortDescription}</Description>}
                                    </Info>
                                </Post>
                            </PostContainer>
                        ))}
                    </Posts>
                    {visible < blogs.length && (
                        <ButtonContainer>
                            <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
                        </ButtonContainer>
                    )}
                </ContentWithPaddingXl>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};